.dir-page {
  .table-header-blob-url {
    text-align: center;
  }

  .table-cell-blob-url {
    padding: 0;
    position: relative;

    .data-table__cell-content {
      align-items: flex-start;
      display: flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      width: 100%;
    }
  }

  .download-files {
    display: block;
    padding: 0;
  }

  .card {
    overflow: visible;
  }

  .card-body {
    overflow-x: scroll;
  }

  .previous-file-data,
  .current-file-data {
    min-height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .previous-file-data {
    display: none;
  }

  .current-file-data {
    margin-top: -5.5px;
  }

  .expand-files,
  .collapse-files {
    width: 25px;
    height: 25px;
    padding: 0;
  }

  .collapse-files {
    display: none;
  }

  .-checkbox-header {
    min-width: 25px;
    padding: 0 !important;

    div {
      margin: 0 auto;
    }
  }

  .previous-file {
    background-color: #f5f5f5;
  }

  input[type="checkbox"] {
    padding: 9px;
    margin: 2px;
    border-width: 2px;
    appearance: auto;
    cursor: pointer;
  }

  .rt-tr > .rt-td:first-child {
    padding: 16px 0;
  }

  .-open {
    .previous-file-data {
      display: flex;
    }

    .expand-files {
      display: none;
    }

    .collapse-files {
      display: block;
    }
  }

  .go-spot-check-survey {
    color: #3c6df1;
    margin-left: 0;
    align-self: baseline;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    font-size: 10pt;
  }
}
