.announcement__container {
  .announcement__header {
    margin-bottom: px(13);
    font-weight: bold;
  }

  .announcement__subheader-container {
    display: flex;
    align-items: center;
    margin-top: px(5);

    .announcement__date {
      margin: 0;
    }

    .announcement__createdby {
      margin: 0;
      padding-left: px(5);
    }
  }

  .announcement__body {
    width: 100%;
    white-space: pre-wrap;

    @include bp(medium) {
      width: 75%;
    }
  }
}

.announcement__delete-button-container {
  position: relative;
  width: 100%;

  .announcement__delete-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.create-announcement__form-container {
  background: $light-form-background-color;
  padding: 0;
}

.announcements__table {
  margin-top: px(20);
}
