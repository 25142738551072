h3 {
  @include bp(mobile) {
    font-size: px(12);
    font-weight: bold;
  }
}

.card-list {
  list-style: none;
  margin-top: px(16);
  width: 100%;
}

.card-list-item {
  padding: 0;
  position: relative;
}

.card {
  background: $white;

  @include bp(mobile) {
    max-width: 100vw;
  }
}

* + .card-list-item {
  margin-top: px(32);
}

.card-header,
.card-footer {
  height: px(60);
  padding: px(20);
  font-size: px(20);
  line-height: 1.2;
  display: flex;
  align-items: center;

  @include bp(mobile) {
    height: px(45);
    padding: px(10);
  }
}

.card-header {
  border-bottom: $table-border;

  a.h2 {
    @include bp(mobile) {
      font-size: px(16);
      margin: 0;
    }
  }
}

.card-body {
  padding: px(10);
  height: 100%;

  label {
    @include bp(mobile) {
      h6 {
        font-size: px(11);
      }

      body {
        font-size: px(16);
      }
    }
  }
}
.card-footer {
  border-top: $table-border;
}

.card-error {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $error-text;
  box-shadow: 0 px(1) 0 0 rgba(230, 230, 230, 0.5);
  color: $white;
  font-size: px(13);
  padding: px(16);
  height: px(36);
  min-width: px(299);
  z-index: 2; // stack on top of react-table

  .text {
    margin-left: px(16);
  }
}

.card-error-bottom {
  background: $gray-background;
  min-height: px(63);
  padding: px(18);

  .error-list {
    list-style: none;
  }
}

.card-header-filters-list {
  list-style: none;
  display: flex;
  align-items: center;
}

.card-header-filter + .card-header-filter {
  margin-left: px(10);
}

.card-header-filters-header {
  margin-right: px(40);
}

.card-header-filter-label {
  // margin-top: px(2);
  margin-bottom: 0;
}
