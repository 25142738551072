.manage-data-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;

  @include isIE() {
    display: block !important;
    width: 100%;
  }

  .header {
    margin-bottom: px(30);
  }

  .file-upload-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    background-color: $white;

    button {
      margin-right: px(20);
      margin-top: px(25);
    }
  }

  .file-upload-item {
    flex-basis: 50%;
    margin: px(15) px(20);
  }

  .file-upload-body {
    height: px(40);
  }

  .manage-data-body {
    flex-basis: 100%;
  }

  .card-bottom {
    background: $gray-background;
    min-height: px(125);
    padding: px(20);

    .error-list,
    .changes-list {
      list-style: none;
    }
  }

  .manage-data-table-controls {
    display: flex;
    justify-content: space-between;
    padding: px(20) 0 px(10) 0;
  }

  .delete-files-button {
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: px(100);
    padding: px(0) px(16);
    color: $body-text-color;
    font-size: px(13);

    .icon {
      height: px(20);
      width: px(20);
    }
  }

  .chain-upload-dropzone {
    border: none;
    cursor: pointer;
    margin-right: px(5);
  }
}
