.about__container {
  width: 100%;
  height: 100%;
  display: block;
  background-color: $about-page-background-color;

  .about__text,
  .about__header,
  .about__logo,
  .about__image,
  .about__movie + .about__header,
  .about__text,
  .about__logo,
  .about__image,
  .about__movie {
    margin-top: px(18);
  }

  .about__body {
    margin: 0 auto;
    max-width: px(560);
    padding: 1rem 0;
  }

  .about__breadcrumb {
    margin-top: 0;
    padding: 0;
  }

  .about__logo {
    width: 100%;
    padding: 0 px(30);
  }

  .about__movie {
    width: 560px;
    height: 315px;
  }

  .about__text {
    @include font-family(sans);
    font-size: px(18);
  }

  .about__text--white {
    color: $white;
  }

  .about__text--center {
    text-align: center;
  }

  .about__header {
    @include font-family(serif);
    font-size: px(24);
  }
}
