.form {
  padding: px(15);
}

.form-group {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-template-rows: auto;
  grid-gap: px(10);

  @include bp(medium) {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-template-rows: auto;
  }
  @include isIE() {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.form-group-span-2 {
  .form-input {
    grid-column: span 2;
  }
}

.form-group-label {
  display: block;
}

.form-group-title {
  margin-bottom: px(8);
}

.form-group-label + .form-group-label {
  margin-top: px(21);
}

.form-input {
  @include font-family(sans);
  font-size: px(13);
  border: px(0.5) solid $form-input-border-color;
  height: px(40);
  padding: px(8);
  color: $body-text-color;
  background: $white;

  @include isIE() {
    flex: 0 0 30%;
    margin: 0 px(10) px(10) 0;
  }

  &.form-textarea {
    height: px(120);
  }
}

.form-button-group {
  display: flex;

  * + * {
    margin-left: px(15);
  }
}

.form-group-label + .form-button-group {
  margin-top: px(30);
}

::placeholder {
  opacity: 0.6;
}
