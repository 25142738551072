@mixin bp($point) {
  @if ($point == "mobile") {
    @media (max-width: 35em) {
      @content;
    }
  } @else if($point == "small_less") {
    @media (max-width: 640px) {
      @content;
    }
  } @else if($point == "medium_less") {
    @media (max-width: 960px) {
      @content;
    }
  } @else if($point == "large_less") {
    @media (max-width: 1280px) {
      @content;
    }
  } @else if($point == "small") {
    @media (min-width: 50em) {
      @content;
    }
  } @else if($point == "medium") {
    @media (min-width: 70em) {
      @content;
    }
  } @else if($point == "large") {
    @media (min-width: 110em) {
      @content;
    }
  } @else {
    @media (min-width: $point) {
      @content;
    }
  }
}

@mixin font-family($font-family) {
  $default-font-family: map-get(map-get($fonts, $font-family), normal);
  $enhanced-font-family: map-get(map-get($fonts, $font-family), enhanced);

  font-family: $default-font-family;

  & .fonts-loaded,
  .fonts-loaded & {
    font-family: $enhanced-font-family, $default-font-family;
  }
}

@mixin button-styles() {
  @include font-family(serif);

  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;
  height: map-get($button-styles, height);
  border-radius: map-get($button-styles, border-radius);
  font-size: map-get($button-styles, font-size);
  font-weight: map-get($button-styles, font-weight);
  padding: map-get($button-styles, padding);
  box-shadow: map-get($button-styles, box-shadow);
}

@function px($num) {
  @return (math.div($num, 16) * 1rem);
}

@mixin isIE() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin IEFlexFix() {
  @include isIE() {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
