.review-feedback-filter-container {
  @include font-family(serif);

  display: flex;
  flex-direction: row;
  align-items: center;
  .header {
    padding-right: px(30);
    padding-left: px(15);
    font-size: px(11);
    color: $body-text-gray-color;
  }
}

.accept-reject-button-group {
  display: flex;
  flex-direction: column;

  @include isIE() {
    .has-tooltip {
      overflow: visible;
    }
  }

  .button {
    padding: 0;
  }

  .button:not(:first-of-type) {
    margin-top: px(6);

    .accepted {
      color: white;
      background-image: linear-gradient(77deg, #3c6df1, #2294f0 55%, #00c6ee);
      outline: none;
    }

    .rejected {
      outline: none;
      color: white;
      background-image: linear-gradient(260deg, #f26f68, #e13c37, #e13c37);
    }
  }

  .csv-link {
    height: px(0);
  }
}

.review-feedback-filter-container {
  @include font-family(serif);

  display: flex;
  align-items: center;

  margin-bottom: px(-30);

  .header {
    padding-right: px(30);
    padding-left: px(15);
    font-size: px(11);
    color: $body-text-gray-color;
  }

  .review-feedback-store-dropdown-container {
    display: flex;
    flex-direction: column;
    margin-right: px(20);
    p {
      margin-top: px(5);
      margin-bottom: unset;
      color: $body-text-gray-color;
      font-size: px(11);
    }
  }

  .review-feedback-multi-filter-dropdown-container {
    display: flex;
    flex-direction: column;
    margin-right: px(20);
    width: px(300);
    p {
      margin-top: px(5);
      margin-bottom: unset;
      color: $body-text-gray-color;
      font-size: px(11);
    }
  }
}
