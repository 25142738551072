@import "~react-datepicker/dist/react-datepicker.css";

// ensure that inputs expand to the entire area available
.react-datepicker__input-container {
  width: 100%;

  > input {
    width: 100%;
  }
}
