.add-comment-form {
  display: flex;
  flex-direction: column;
  background: $form-background-color;
  margin: 0;
  padding: px(20);

  @include bp(medium) {
    flex-direction: row;
  }

  .left-side {
    display: flex;
    flex-direction: column;
    margin-right: 0;
    margin-bottom: px(20);
    flex-basis: 100%;
    height: px(190);
    justify-content: space-between;
    min-width: px(240);

    @include bp(medium) {
      flex-basis: 50%;
      margin-right: px(20);
      margin-bottom: 0;
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-basis: 100%;

    @include bp(medium) {
      flex-basis: 50%;
    }

    .comment-text {
      vertical-align: top;
      align-self: start;
      background: $white;
      width: 100%;
      height: px(140);
      margin-bottom: px(20);
      border-width: px(1);
      border-color: $border-color;
      padding: px(8) px(10);
      font-size: px(13);
    }

    .char-count-and-submit-group {
      display: flex;
      justify-content: space-between;

      @include isIE() {
        .has-tooltip {
          overflow: visible;
        }
      }
    }

    .divider{
      width:15px;
      height:auto;
      display:inline-block;
  }

    .comment-char-count {
      font-size: px(12);
      padding: px(5);

      &.warn {
        color: $error-text;
      }
    }
  }

  .project-comment-dropdown {
    [class$="-option"] {
      border-bottom: $gray-background solid 1px;
    }
    &:last-child {
      border-bottom: none;
    }
    label {
      font-weight: bold;
    }
    .product-dropdown--action {
      font-weight: bold;
    }
    .product-dropdown--reason {
      color: $h6-gray;
    }
    .product-dropdown--product {
      padding-right: .75em;
      color: $h4-gray;
    }
    .product-dropdown--sub-product {
      @extend .product-dropdown--product;
      &:before {
        content: "\2192";
        padding-right: .75em;
        display: inline-block;
      }
    }
  }
}
