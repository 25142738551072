.attachments-list,
.attachments-icon-list {
  list-style: none;
}

.attachments-icon-list {
  display: flex;
  flex-direction: row;
}

.attachments-list {
  display: flex;
  flex-direction: row;
  align-items: center;

  & .attachments-list-item:first-child {
    flex-shrink: 0;
  }

  & .attachments-list-item:last-child {
    margin-left: auto;
    flex-shrink: 0;
  }

  & .attachments-list-item:nth-child(2) {
    flex-shrink: 1;
    flex-basis: 80%;
    margin-left: px(20);
  }
}

.attachments-data-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.attachments-details {
  font-size: px(11);
  padding-top: px(5);
  color: $body-text-color;
}

.attachments-pill {
  margin-right: px(6);
}

.attachments-created-at {
  margin-top: px(-6);
}

.attachments-image {
  width: px(22);
  height: px(30);

  @include bp(medium) {
    width: px(38);
    height: px(50);
  }
}

.view-button,
.download-button,
.attachments-back-button {
  @include button-styles();

  outline: none;
  border: none;
  box-shadow: none;
  background: transparent;
}

.view-button {
  &,
  &.hidden {
    display: none;
  }

  @include bp(medium) {
    display: flex;
  }
}

// React-Table fixes
.ReactTable {
  @include isIE() {
    overflow: auto;
  }
}
// unfortunately need the !important tags here to override styles of ReactTable
.attachments-table-header {
  display: none !important;
}

.attachments-back-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: math.div(16, 16) * 1rem;
  border-bottom: math.div(1, 16) * 1rem solid $border-color;
  background: transparent;
  box-shadow: none;
  outline: none;

  height: math.div(49, 16) * 1rem;
  width: 100%;
}

// same styles as Table header
.attachments-back-button {
  padding: 0;

  > h6 {
    margin-left: math.div(10, 16) * 1rem;
  }
}

.attachments-pdf-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  min-height: px(600);

  .attachments-pdf {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
