.my-feedback-filter-container {
  @include font-family(serif);

  display: flex;
  align-items: center;
  margin-bottom: px(-30);

  .header {
    padding-right: px(30);
    padding-left: px(15);
    font-size: px(11);
    color: $body-text-gray-color;
  }
}

.my-feedback-store-dropdown-container {
  display: flex;
  flex-direction: column;
  p {
    margin-top: px(5);
    margin-bottom: unset;
    color: $body-text-gray-color;
    font-size: px(11);
  }
}

.export-feedback-button {
  @include font-family(serif);

  padding: map-get($button-styles, padding);
  outline: none;
  border: none;
  box-shadow: none;
  background: transparent;
  color: $link-color;
}
