.date-form-group {
  display: flex;
  flex-direction: row;
  max-width: px(500);
  margin-right: auto;

  @include bp(mobile) {
    display: flex;
    flex-direction: column;
  }
}

.date-label-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include bp(mobile) {
    margin-bottom: px(10);
  }
}

.note-form {
  background: $form-background-color;
  border-bottom: px(2) solid $border-color;

  @include bp(mobile) {
    width: 100vw;
  }
}
.note-form-input-group {
  display: flex;
  flex-direction: row;

  @include bp(mobile) {
    display: flex;
    flex-direction: column;
  }
}

.implementation-dropdown {
  margin-right: 10px;

  @include bp(mobile) {
    margin-right: auto;
  }
}

.note-text {
  vertical-align: top;
  align-self: start;
  background: $white;
  width: 100%;
  height: px(140);
  margin-bottom: px(20);
  margin-left: px(10);
  border-width: px(1);
  border-color: $border-color;
  padding: px(8) px(10);
  font-size: px(13);

  @include bp(mobile) {
    vertical-align: top;
    align-self: start;
    background: $white;
    width: calc(100vw - #{px(20)});
    height: px(140);
    margin-left: 0;
    margin-bottom: px(20);
    border-width: px(1);
    border-color: $border-color;
    padding: px(8) px(10);
    font-size: px(13);
  }
}

.comment-char-count {
  font-size: px(12);
  padding: px(5);

  &.warn {
    color: $error-text;
  }

  @include bp(mobile) {
    display: none;
  }
}

.submit-group {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  button {
    margin-left: px(10);
  }

  @include bp(mobile) {
    button {
      margin-left: auto;
    }
  }
}

.implementation-dropdown {
  display: flex;
  flex-direction: column;

  @include bp(mobile) {
    margin-bottom: px(10);
  }
}

.react-datepicker__time-container {
  width: px(100);

  .react-datepicker__time .react-datepicker__time-box {
    width: px(100);
  }
}
