$bodyLevels: (
  b1: (
    font-size: 1rem,
    line-height: 1.13,
  ),
  b2: (
    font-size: (
      math.div(14, 16)
    ) * 1rem,
    line-height: 1.14,
  ),
  b3: (
    font-size: (
      math.div(13, 16)
    ) * 1rem,
    line-height: 1.15,
  ),
  b4: (
    font-size: (
      math.div(11, 16)
    ) * 1rem,
    font-weight: 500,
    font-family: serif,
    color: $body-text-gray-color,
  ),
  error: (
    font-size: (
      math.div(13, 16)
    ) * 1rem,
    color: $error-text,
  ),
);

@each $level, $item in $bodyLevels {
  .#{$level} {
    @include font-family(if(map-has-key($item, font-family), map-get($item, font-family), "sans"));

    font-size: map-get($item, font-size);
    color: if(map-has-key($item, color), map-get($item, color), $body-text-color);
    font-weight: if(map-has-key($item, font-weight), map-get($item, font-weight), normal);
    line-height: if(map-has-key($item, line-height), map-get($item, line-height), normal);
  }
}
