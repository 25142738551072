.profile {
  display: none;
  background: $white;
  font-size: px(12);

  @include bp(medium) {
    display: grid;

    @include isIE() {
      display: flex;
    }

    grid-area: profile;
    border-left: px(2) solid $interior-border-color;
  }
}

.profile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 px(5);
}
