.create-project-container {
  .create-project-info {
    width: 100%;
  }

  .link {
    color: $link-color;
  }

  .header {
    margin-bottom: px(30);
  }

  .header-area {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .create-project-buttons {
    display: flex;
    flex-direction: row;

    button {
      margin-left: px(20);
    }
  }

  .file-upload-list {
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: space-between;
    width: 100%;
  }

  .create-project-link {
    color: $link-color;
  }

  .file-upload-item {
    flex-basis: 25%;
  }

  .file-upload-item + .file-upload-item {
    margin-left: px(16);
  }

  .box-list {
    list-style: none;
    margin-top: px(16);
  }

  .box-item {
    background: $white;
    padding: 0;
    position: relative;
  }

  * + .box-item {
    margin-top: px(32);
  }

  .box-header,
  .box-footer {
    height: px(60);
    padding: px(20);
    display: flex;
    align-items: center;
  }

  .box-error {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: $error-text;
    box-shadow: 0 1px 0 0 rgba(230, 230, 230, 0.5);
    color: $white;
    font-size: px(13);
    padding: px(16);
    height: px(36);
    min-width: px(299);
    z-index: 2; // stack on top of react-table

    .text {
      margin-left: 1rem;
    }
  }

  .box-error-bottom {
    background: $gray-background;
    min-height: px(63);
    padding: px(18);

    .error-list {
      list-style: none;
    }
  }
  .data-preview .card-bottom {
    padding: px(18);
    li {
      list-style: none;
    }
  }
}
