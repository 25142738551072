.navigation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 2; // stack on top of react-table

  &.toggled {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0;
    background: $sidebar-color;
  }

  @include bp(medium) {
    &,
    &.toggled {
      padding-top: px(16);
      padding-left: px(10);
    }
  }
}

.navigation-links,
.navigation-group-links {
  list-style: none;
  position: relative;
}

.navigation-links {
  display: none;
  height: 100%;
  width: 100%;
  flex-direction: column;
  -ms-overflow-style: none;
  overflow-y: auto;

  .toggled & {
    display: flex;
    padding-left: px(10);
  }

  @include bp(medium) {
    display: flex;
    flex-direction: column;
  }
}

.navigation-group {
  & + .navigation-group {
    margin-top: px(30);
  }

  &.bottom {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    padding-bottom: px(30);
  }
}

.navigation-group-name {
  margin-bottom: px(5);
  color: $link-color-inactive;
}

.navigation-item + .navigation-item {
  @include bp(medium) {
    margin-top: px(10);
  }
}

.navigation-link-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-link {
  margin-left: px(6);
  color: $link-color-inactive;
}

.navigation-image {
  margin-right: px(6);
  height: px(30);
  width: px(30);
}

.navigation-item {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  height: px(30);

  &.active {
    & .navigation-link {
      color: $white;
      font-weight: bold;
    }
  }
}

.mobile-only {
  @include bp(medium) {
    display: none;
  }
}
