.section-header {
  display: flex;
  justify-content: flex-start;
  margin: px(10) 0 px(30);

  @include bp(mobile) {
    margin: px(10) 0 px(-15) 0;
  }

  h1 {
    margin-right: 1rem;
    @include bp(mobile) {
      font-size: px(18);
    }
  }
  .section-header-button-group {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    padding: px(5);

    .import-export-button {
      @include font-family(serif);

      padding: map-get($button-styles, padding);
      outline: none;
      border: none;
      box-shadow: none;
      background: transparent;
      color: $link-color;
      margin-left: unset;
      margin-right: unset;

      &.disabled {
        color: $disabled-button-background;

        &:hover {
          cursor: default;
        }
      }
    }
  }
}
