.button {
  @include button-styles();

  color: map-get(map-get($button-styles, primary), color);
  background: map-get(map-get($button-styles, primary), background);

  &.secondary {
    color: map-get(map-get($button-styles, secondary), color);
    background: map-get(map-get($button-styles, secondary), background);
  }

  &.warning {
    color: map-get(map-get($button-styles, warning), color);
    background: map-get(map-get($button-styles, warning), background);
  }

  &.accept {
    outline: none;
  }

  &.reject {
    background: white;
    color: #e13c37;
    outline: none;
  }

  &:disabled {
    color: map-get(map-get($button-styles, disabled), color);
    background: map-get(map-get($button-styles, disabled), background);
    cursor: auto;
  }
}

.export-button {
  @include button-styles();
  outline: none;
  border: none;
  box-shadow: none;
  background: transparent;
  color: $link-color;
  margin-left: unset;
  margin-right: unset;
  text-transform: unset;
  font-size: px(15);
  font-weight: 500;

  &.disabled {
    color: $disabled-button-background;

    &:hover {
      cursor: default;
    }
  }
}

.error-home-button {
  @include button-styles();
  @include font-family(sans);
  font-size: px(20);
  font-weight: bold;
  width: px(240);
  padding: px(18) 0;
  height: auto;
  border-radius: px(4);
  border: none;
  background: $white;
}

.icon-button {
  outline: none;
  border: none;
  box-shadow: none;
  background: transparent;
  margin-left: unset;
  margin-right: unset;
  text-transform: unset;
  font-size: px(15);
  font-weight: 500;

  &.disabled {
    color: $disabled-button-background;
  }

    &.hoverable{
      &:hover {
        background-color: #f3f3f3;
        border-radius: 10%;
      }
    }

}