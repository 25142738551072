.collapse-container {
  display: flex;
  flex-direction: column;
}

.collapse-header {
  justify-content: space-between;
}

.collapse-body {
  overflow: visible;
  transition: max-height 0.5s ease;
  max-height: 100vh; // ensure that all content can be seen on smaller breakpoints

  &.collapsed {
    overflow: hidden;
    max-height: 0;
  }
}

.collapse-button {
  outline: none;
  border: none;
  margin: 0;
  background-color: $white;
  // use padding from supplied header
  padding: 0;
}
