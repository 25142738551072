.legend {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: px(10);
  width: px(250);

  .legend-label {
    font-size: px(11);
    min-width: px(100);
    width: px(100);
    white-space: nowrap;
  }
}

.chart-wrapper {
  max-width: px(280);
}

.legend > div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.legend > div > div {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.project-status-total {
  text-align: center;
  padding: px(5);
}
