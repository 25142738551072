table {
  overflow-x: scroll;
  border-style: solid;
  width: 100%;
}

th {
  text-align: left;
  font-weight: 500;
}

td,
th {
  padding: 0.5rem;
}

td {
  border-bottom-width: 0.0625rem;
  border-bottom-style: solid;
}

/* Safari will resize on an overflow element when a child gets a box-shadow. This will cause the scroll to reset (scroll to top).*/
/* Solution is to use a psuedo element positioned absolute that will not affect the flow. */
.table-row-clickable {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &:hover:after {
    z-index: 10;
    border-top: 0;
    box-shadow: 0 0 px(1) px(2) $border-color;
  }
}
