.grid-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;

  @include bp(medium) {
    display: grid;
    grid-template-columns: $column-sidebar-width $column-breadcrumb-width $column-main-width $column-search-width $column-help-width $column-support-documents-width $column-profile-width;
    grid-template-rows: $row-header-height 1fr;
    grid-template-areas:
      "logo  breadcrumb  empty  search   support   support-documents  profile"
      "navigation main   main   main    main     main     main      ";
  }

  @include isIE() {
    overflow: hidden;
  }
}



.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;

  @include bp(medium) {
    display: grid;
  }
}

.empty {
  display: none;
  background: $white;

  @include bp(medium) {
    display: grid;
    grid-area: empty;
  }
}

.breadcrumb {
  display: flex;
  justify-content: flex-start;
  background: transparent;

  @include bp(medium) {
    display: grid;
    grid-area: breadcrumb;
    @supports (display: grid) {
      justify-content: start;
    }
    background-color: $white;
  }
}

.header-link {
  color: $header-link-color-inactive;
  display: flex;
  align-items: center;

  &:hover,
  &:active {
    color: darken($header-link-color-inactive, 20%);
  }
}

.header-link-active {
  color: $header-link-color-active;

  &:hover,
  &:focus {
    color: darken($header-link-color-active, 20%);
  }
}

.support {
  background-color: $white;
  display: none;
  margin:4px 2px 4px 2px;

  .support-link {
    color: $link-color;
    font-size: px(14);
    display: flex;
    align-items: center;
  }

  @include bp(medium) {
    display: grid;
    grid-area: support;
    border: px(2) solid $sidebar-color;

    @include isIE() {
      display: flex;
    }

    .icon {
      // TODO: remove once we get a new support svg
      margin-left: -7px;
    }
  }
}

.support-documents {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  display: none;
  margin:4px 2px 4px 2px;

  .support-link {
    color: $link-color;
    font-size: px(14);
    display: flex;
    align-items: center;
  }

  @include bp(medium) {
    display: grid;
    grid-area: support-documents;
    border: px(2) solid $sidebar-color;

    @include isIE() {
      display: flex;
    }

    .icon {
      // TODO: remove once we get a new support svg
      margin-left: -7px;
    }
  }
}


.logo {
  display: none;

  @include bp(medium) {
    display: grid;

    @include isIE() {
      display: flex;
    }

    grid-area: logo;
    background: $sidebar-color;
  }
}

.grid-navigation {
  display: flex;
  background: $sidebar-color;
  justify-content: flex-start;
  min-height: $row-header-height;
  position: relative;

  @include bp(medium) {
    display: grid;
    @supports (display: grid) {
      justify-content: start;
    }
    grid-area: navigation;
  }
}

.main {
  display: grid;
  grid-area: main;
  @supports (display: grid) {
    align-items: stretch;
    justify-content: stretch;
  }
  padding: px(20);
  overflow-y: auto;

  @include isIE() {
    display: block;
  }
}

.manage-data-body {
  h1 {
    @include bp(mobile) {
      font-size: px(18);
      margin: px(10) 0 px(-10) px(10);
    }
  }

  h2 {
    @include bp(mobile) {
      font-size: px(13);
      font-weight: bold;
    }
  }
}

// IE11 Fixes for react-table
@include isIE() {
  .create-project-container .ReactTable .rt-table {
    max-height: px(560);
  }
  .ReactTable {
    .rt-table {
      overflow-y: hidden !important;
    }

    .rt-tbody {
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }

    .rt-th,
    .rt-td {
      white-space: normal !important;
      line-height: normal !important;
      span {
        line-height: normal !important;
        font-size: px(11) !important;
      }
    }
    .rt-td {
      min-height: 100%;

      .project-chain-name div {
        display: flex;
        align-content: center;

        span {
          margin-top: px(10);
        }
      }
    }
  }
}
