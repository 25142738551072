.hamburger-container {
  @include bp(medium) {
    display: none;
  }

  .hamburger {
    background: transparent;
    border: none;

    &:focus {
      outline: none;
    }
  }

  .hamburger-icon {
    height: px(30);
    width: px(30);
  }
}
