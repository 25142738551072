a {
  text-decoration: none;
  color: $white;

  &:hover,
  &:focus {
    color: darken($white, 20%);
  }
}

.body-link {
  color: $body-link-color;

  &:hover,
  &:focus {
    color: darken($body-link-color, 20%);
  }
}
