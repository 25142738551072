.mobile-table-container {
  background-color: $form-background-color;
  padding-bottom: px(5);
  max-width: 100vw;
}

.mobile-table {
  background-color: $white;

  .mobile-table-row {
    border: solid 1px $gray-background;
    margin: px(10) 0;
  }

  .mobile-table-row-header {
    padding-left: px(10);
    font-weight: bold;
    font-size: px(11);
    width: px(130);
    text-transform: uppercase;
    color: $table-header-color;
  }
  .mobile-table-row-data {
    border: none;
    display: flex;
    font-size: px(13);
  }

  .mobile-table-button {
    border: none;
    color: $body-text-color;
    font-size: px(13);
    margin-left: auto;
    background: transparent;
    display: flex;
    justify-content: center;
    text-transform: none;
    font-weight: normal;
    font-size: px(13);
    padding: 0;

    img {
      margin-left: px(6);
    }

    &.delete {
      max-width: px(72);
      max-height: px(20);
    }

    &.download {
      max-width: px(100);
    }
  }
}
