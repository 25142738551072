.survey-form {
  // border-bottom: px(2) solid $border-color;
}

.surveys__table {
  margin-top: px(20);
}

.survey-title {
  @include font-family(sans);
  background: $white;
  font-size: px(13);
  border: px(0.5) solid $form-input-border-color;
  height: px(40);
  padding: px(8);
  color: $body-text-color;
  width: px(345);
  margin-right: px(10);

  @include bp(mobile) {
    max-width: 93vw;
    margin-bottom: px(10);
  }
}

.survey-url {
  @include font-family(sans);
  background: $white;
  font-size: px(13);
  border: px(0.5) solid $form-input-border-color;
  height: px(40);
  padding: px(8);
  color: $body-text-color;
  width: px(700);
  margin-bottom: px(10);

  @include bp(mobile) {
    max-width: 93vw;
  }
}

.title-url-group {
  display: flex;
  flex-direction: row;
  margin-bottom: px(10);

  @include bp(mobile) {
    display: flex;
    flex-direction: column;
  }
}

.form {
  @include bp(mobile) {
    padding: 0 0 0 0;
  }
}

.submit-survey-button {
  margin-left: auto;
}

.survey-table-truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
  }
}
