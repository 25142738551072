.downloads-header {
  display: flex;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.download-all-button {
  display: flex;
  flex-direction: row;

  .spin-right {
    @include isIE() {
      width: px(40);
    }
  }
}

.download-error {
  display: inline-block;
  // This is going to live next to a button so we'll use the button height to align things.
  line-height: map-get($button-styles, height);
  margin-right: px(20);
}

.download-message {
  display: flex;
  align-items: center;
  margin-right: px(10);
}
