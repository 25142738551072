.login-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: px(640);
  padding: px(16);

  .login-form-field + .login-form-field {
    margin-top: px(32);
  }

  .login-form-field + .login-form-button {
    margin-top: px(64);
  }
}

.login-form-field {
  font-size: px(20);
  box-sizing: border-box;
  height: px(56);
  width: 100%;
  padding: px(8);
}

.login-form-forgotpassword-container {
  text-align: center;
}

.login-form-forgotpassword {
  vertical-align: middle;
  font-size: inherit;
  color: $link-color;
}
