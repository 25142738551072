.uploads-container {
  @include isIE() {
    flex: 1 0 auto;
  }
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .file-upload-header {
    margin: 0;
    height: px(26);
  }

  .blue {
    color: $link-color;
  }

  .file-upload-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: px(1) dashed $link-color;

    width: 100%;
    height: px(100);
    padding: px(20);
    line-height: 1.14;

    & > input {
      border: none;
      height: 100%;
      width: 100%;
      pointer-events: auto !important;
    }

    &:active,
    &:hover,
    &:focus,
    &.active {
      background: $white;
    }
  }
}

.basic-import-button {
  @include font-family(serif);
  outline: none;
  border: none;
  box-shadow: none;
  background: transparent;
  color: $link-color;
  margin-left: unset;
  margin-right: unset;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    color: $disabled-button-background;

    &:hover {
      cursor: default;
    }
  }
}

.upload-success-message {
  display: flex;
  align-items: center;
}
