$headerLevels: (
  h1: (
    font-size: (
      math.div(24, 16)
    ) * 1rem,
    line-height: 1.25,
  ),
  h2: (
    font-size: (
      math.div(20, 16)
    ) * 1rem,
    line-height: 1.2,
  ),
  h3: (
    font-size: (
      math.div(18, 16)
    ) * 1rem,
    line-height: 1.11,
  ),
  h4: (
    font-size: (
      math.div(15, 16)
    ) * 1rem,
    font-weight: 500,
  ),
  h5: (
    font-size: (
      math.div(12, 16)
    ) * 1rem,
    font-weight: 500,
  ),
  h6: (
    font-size: (
      math.div(11, 16)
    ) * 1rem,
    font-weight: bold,
    text-transform: uppercase,
    color: $h6-gray,
    letter-spacing: 0.5,
  ),
);

@each $level, $item in $headerLevels {
  #{$level},
  .#{$level} {
    @include font-family("serif");

    font-size: map-get($item, font-size);
    color: if(map-has-key($item, color), map-get($item, color), $body-text-color);
    font-weight: if(map-has-key($item, font-weight), map-get($item, font-weight), normal);
    line-height: if(map-has-key($item, line-height), map-get($item, line-height), normal);
    text-transform: if(map-has-key($item, text-transform), map-get($item, text-transform), normal);
    letter-spacing: if(map-has-key($item, letter-spacing), map-get($item, letter-spacing), normal);
    word-break: normal;
    word-wrap: normal;
  }
}
