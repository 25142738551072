.error-container {
  background: $signin-background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  color: $white;
}

.error-message-container {
  margin-top: px(26);
  margin-bottom: px(79);
  padding: 0 px(10);
  max-width: px(667);
  font-size: px(30);
  line-height: 1.27em;
}
