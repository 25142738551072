.project-info-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: px(20);
  width: 100%;

  &.project-info-list-final-row{
    margin-bottom: px(40);
  }
}

.project-info-dropdown {
  width: 100%;
}

.project-info-list-item {
  display: flex;
  flex-direction: column;
  margin-right: px(15);
  width: 100%;

  .project-info-label {
    margin-bottom: px(15);
  }

  .project-info-input {
    background: white;
    border: none;
    height: px(26);
    @include isIE() {
      height: px(42);
    }
  }
}

.project-info-edit-save {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: px(30);
}

.project-info-edit-save-link {
  color: $link-color;
  margin-left: px(40);
}

.react-datepicker__day--selected {
  background-color: $link-color;
}
