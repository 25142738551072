html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: $background-color;
  font-size: $base-font-size;
  line-height: $base-line-height;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

html,
body {
  height: 100%;
}

#root {
  @include font-family("sans");

  height: 100%;
  color: $body-text-color;
}

em {
  font-style: italic;
}

strong,
b {
  font-weight: bold;
}

code,
pre {
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-weight: normal;
  font-size: 0.875rem;
}

hr {
  border-style: solid;
}

.no-wrap {
  white-space: normal !important;
}
