ol,
ul {
  list-style-position: outside;
  padding: 0;
}

li {
  padding: 0;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}
