
.dialog__main-text{
    //@include font-family(sans);
    font-family: $enhanced-sans;
    color: #4A4A4A;
    font-size:px(18);
    text-align: center;
    line-height: 20px;
}

.dialog__subtext{
    //@include font-family(sans);
    font-family: $enhanced-sans;
    color: #4A4A4A;
    font-size:px(14);
    //text-align: center;
    line-height: 20px;
}

//TODO not sure if this is being overridden somewhere
.dialog__primary-button-content{
    //@include font-family(sans);
    font-family: $enhanced-sans;
    text-transform: uppercase;
}

.dialog__secondary-button-content{
    //@include font-family(sans);
    font-family: $enhanced-sans;
    text-transform: uppercase;
}

.dialog__button-row-text {
  font-family: $enhanced-sans;
  padding-right: px(20);
}

/*In order to use \n new line characters in the dialogMainText and dialogTitleText props,
you must write dialogMainText = {"Line 1 \n Line 2"} instead of dialogMainText = "Line 1 \n Line 2" when using the component
*/
.dialog__error-text{
  //Although we have $error-text defined, that color is slightly different from the error icon color
  color: #E13C37;
}
