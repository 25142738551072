.showall-link {
  color: $link-color;
}

.mywork-container {
  .empty-state {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    .empty-state-body {
      margin-top: px(12);
      padding-bottom: px(40);
      color: $h4-gray;
    }
  }

  @include isIE() {
    flex: 1 0 auto;
  }
}
