.data-table {
	position: relative;
}

.data-table__table {
	table-layout: fixed;

	.data-table.--responsive & {
		display: block;

		@include bp(small) {
			display: table;
		}
	}
}

.data-table__body {
	.data-table.--responsive & {
		display: block;

		@include bp(small) {
			display: table-row-group;
		}
	}
	a {
		color: $body-link-color;
	}
}

.data-table__header {
	.data-table.--responsive & {
		// Only visually hide for mobile, so headers are still
		// available for screen readers.
		position: absolute;
		clip: rect(1px, 1px, 1px, 1px);
		padding: 0;
		border: 0;
		height: 1px;
		width: 1px;
		overflow: hidden;

		@include bp(small) {
			clip: unset;
			position: unset;
		}
	}
}

.data-table__row {
	.data-table.--responsive & {
		display: block;

		& + .data-table__row {
			border-top: px(8) solid $background-color;
		}

		@include bp(small) {
			display: table-row;

			& + .data-table__row {
				border-top: unset;
			}
		}
	}
}

.data-table__cell {
	border-bottom-width: px(1);
	border-color: $border-color;
	border-style: solid;
	padding: px(16);
	vertical-align: top;

	.data-table.--responsive & {
		border-left-width: px(1);
		border-right-width: px(1);
		display: flex;

		&:first-of-type {
			border-top-width: px(1);
		}

		@include bp(small) {
			border-left-width: 0;
			border-right-width: 0;
			display: table-cell;

			&:first-of-type {
				border-top-width: 0;
			}
		}
	}
}

.data-table__cell-content {
	.data-table.--responsive & {
		width: 66.66%;

		@include bp(small) {
			width: auto;
		}
	}
}

.data-table__col-header {
	font-weight: 600;
	text-transform: uppercase;
	border-bottom: px(1) $border-color solid;
	padding: px(16) 0 px(16) px(16);

	.data-table.--responsive & {
		border-bottom: 0;
		padding: 0;
		width: 33.33%;
		display: block;

		@include bp(small) {
			display: table-cell;
			width: auto;
			padding: px(16);
			border-bottom: px(1) $border-color solid;

			&[hidden] {
				display: none;
			}
		}
	}
}

.data-table__col-sort-icon {
	margin-left: 1ch;
}

.data-table__row.--clickable {
	cursor: pointer;
}

.data-table__row.--clickable:hover {
	@include bp(small) {
		outline: 2px solid $border-color;

		.data-table__cell {
			border-bottom-color: transparent;
		}
	}
}

.data-table__empty {
	min-height: px(320);
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	font-size: px(14);

	@at-root #{&}-message {
		margin: px(8) 0;
		color: #8d8d92;
	}
}



.data-table__page-nav {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	padding: 3px;
	border-top: 1px solid $border-color;
	box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 10%);

	.data-table.--responsive & {
		border-top: px(8) solid $background-color;

		@include bp(small) {
			border-top: 1px solid $border-color;
		}
	}

	& button {
		background: #e6e6e6;
		border: 0;
		border-radius: px(3);
		color: #757575;
		flex-grow: 1;
		font-family: inherit;
		font-size: 100%;
		margin: 0;
		padding: 0;
		text-align: center;

		&:disabled {
			background: #f9f9f9 !important;
			color: #ccc !important;
			cursor: not-allowed;
		}

		&:hover {
			background: #bbb;
			color: #fff;
		}

	}

	@at-root #{&}-status {
		align-items: center;
		display: flex;
		flex-grow: 1.5;
		justify-content: center;
	}
}
