.datepicker-open-container {
  width: px(200);

  /* Because we adjust the width of the time picker,
  * we have to move the next arrow to the left a bit
  */
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: px(110);
  }

  .react-datepicker-popper {
    width: px(400);
  }

  .date-input {
    background: white;
    border: px(1) solid #cccccc;
    height: px(26);
  }

  @include isIE() {
    .date-input {
      font-size: px(14);
      padding: 0 12px;
      height: px(33);
      width: 100%;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      width: 100%;
    }
  }
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .datepicker-button-group {
    display: flex;
    padding-top: px(10);
  }

  .datepicker-cancel-button {
    margin-left: auto;
  }

  .datepicker-save-button {
    margin-right: auto;
  }
}

.datepicker-closed-container {
  display: flex;
}

.datepicker-open-button {
  border: none;
  outline: none;
  background: transparent;
}

.date-picker-field {
  @include font-family(sans);
  text-align: left;
  background: $white;
  font-size: px(13);
  border: 1px solid #cccccc;
  height: px(32);
  padding: px(8);
  color: $body-text-color;
  margin-right: px(30);
  width: px(200);
}

.mobile-datepicker {
  .react-datepicker {
    max-width: 100vw;
    max-height: 70vh;
  }
}
