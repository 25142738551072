.page {
  &.mt-negative {
    h1 {
      @include bp(mobile) {
        margin: px(0) 0 px(-10) px(10);
        padding: 0 px(10) px(10) 0;
      }
    }
  }
  h1 {
    @include bp(mobile) {
      font-size: px(18);
      margin: px(10) 0 px(-10) px(10);
    }
  }

  h2 {
    @include bp(mobile) {
      font-size: px(13);
      font-weight: bold;
    }
  }

  @include isIE() {
    flex: 1 0 auto;
  }
}
