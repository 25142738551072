.manage-all-data-grid {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  /* autoprefixer: ignore next */
  grid-auto-rows: px(87);
  grid-row-gap: px(10);
  grid-column-gap: px(10);
  list-style: none;

  @include bp(medium) {
    grid-template-columns: auto auto;
    grid-template-rows: auto;
  }
  @include IEFlexFix();
}

.manage-all-data-grid-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: $white;
  padding: px(26);

  @include isIE() {
    flex: 0 1 44%;
    margin: 0 px(10) px(10) 0;
  }
}

.manage-all-data-container {
  .header {
    margin-bottom: px(30);
  }
  @include isIE() {
    flex: 1 0 auto;
  }
}
