.breadcrumb__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: px(20) 0;
  padding-left: px(20);

  @include bp(mobile) {
    display: none;
  }
}

.breadcrumb__list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:not(:first-of-type) {
    margin-left: px(10);
  }
}

.breadcrumb__text {
  margin-left: px(10);
}

.breadcrumb__text--active {
  color: $link-color;
}

.breadcrumb__text--white {
  color: $white;
}
