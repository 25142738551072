.manage-data-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;

  .header {
    margin-bottom: px(30);
  }

  .file-upload-list {
    display: flex;
    align-items: center;
    list-style: none;
    background-color: $white;
    justify-content: space-between;

    button {
      margin-right: px(20);
      margin-top: px(25);
    }
  }

  .file-upload-item {
    flex-basis: 50%;
    margin: px(15) px(20);
  }

  .file-upload-body {
    height: px(40);
  }

  .manage-data-body {
    flex-basis: 100%;
  }

  .manage-data-table-controls {
    display: flex;
    justify-content: space-between;
    padding: px(20) 0 px(10) 0;

    a {
      display: none;
    }
  }

  @include isIE() {
    .ReactTable {
      .rt-th,
      .rt-td {
        white-space: normal;
        line-height: normal !important;
      }
    }
  }
}

.delete-comment-button {
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: px(0) px(16);
  color: $body-text-color;
  font-size: px(13);
  margin-left: auto;
  background: transparent;

  .icon {
    height: px(20);
    width: px(20);
  }
}

.Popover-body {
  display: inline-flex;
  flex-direction: column;
  padding: px(8) px(16);
  background: $white;
  color: $body-text-color;
  box-shadow: 0 2px 10px 0 rgba(150, 150, 150, 0.4);
  font-family: $sans;
  font-weight: 500;

  .button {
    font-family: $sans;
    display: inline-flex;
    margin-inline-end: px(16);
    border: none;

    &.secondary {
      color: #8d8d92;
    }
  }
}

.Popover-tipShape {
  fill: $white;
}

.delete-all-mappings {
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: px(0) px(16);
  font-size: px(13);
  margin-left: auto;
  color: $body-text-gray-color;
}
