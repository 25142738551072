input,
textarea {
  font-family: sans-serif;
  font-size: $base-font-size;
  border-style: solid;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  resize: none;
  background-color: inherit;
  -webkit-appearance: none;

  padding: 1rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

textarea {
  overflow: auto;
}

button,
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: none;

  font-size: $base-font-size;
  padding: $base-font-size;
}

input[type="checkbox"] {
  padding: 9px;
  margin: 2px;
  border-width: 2px;
  appearance: auto;
  cursor: pointer;
}
