.button-with-copy-comments__comment-card{
    background-color: $form-background-color;
    border-radius: px(15);
    align-items:flex-start;
}

.button-with-copy-comments__container{
    display: flex;
    justify-content: flex-start;
    margin-bottom: px(10);
    padding-bottom: px(10);
}

.button-with-copy-comments__comment-primary{
    //@include font-family(sans);
    font-family: $enhanced-sans;
    font-size: px(16);
    text-align: left;
    padding-left: px(20);
    padding-top: px(15);
    white-space: pre-wrap;
}

.button-with-copy-comments__comment-secondary{
    //@include font-family(sans);
    font-family: $enhanced-sans;
    font-size: px(13);
    color: $body-text-gray-color;
    margin-right: px(25);
    margin-bottom: px(10);

    &.bold{
        font-weight: 700;
        margin-right: unset;
        padding-left: px(20);
        padding-right: px(5);
    }
}
