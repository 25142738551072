@import "mixins";

// $enhanced-sans: "Nimbus Sans Regular";
$enhanced-sans: Arial;
$enhanced-serif: "Roboto";

$sans: sans-serif;
$serif: "Times New Roman", Times, serif;

$base-font-size: px(16);
$base-line-height: 1.75;

$base-vertical-space: px(32);
$base-horizontal-space: px(32);

// layout
$column-sidebar-width: px(210);
$column-breadcrumb-width: auto;
$column-main-width: 8fr;
$column-help-width: px(170);
$column-support-documents-width: px(170);
$column-search-width: px(300);
$column-alerts-width: px(60);
$column-profile-width: px(300);
$row-header-height: px(62);
$row-main-height: auto;

// colors
$border-color: #ebebeb;
$interior-border-color: #eeecea;
$sidebar-color: #d0021b;
$background-color: #f8f8f8;
$light-form-background-color: #fcfcfc;
$form-background-color: #f5f5f5;
$table-header-color: #797a7e;
$body-text-color: #4a4a4a;
$body-text-gray-color: #797a7e;
$white: #fff;
$h4-gray: #8d8d92;
$h6-gray: #797a7e;
$light-gray: #f0f0f0;
$error-text: #d53626;
$gray-background: #ebebeb;
$table-border: 1px solid $gray-background;
$disabled-button-background: #d8d8d8;
$disabled-button-color: #8d8d92;
$form-input-border-color: #8d8d92;
$signin-background-color: #e13c37;
$tooltip-background-color: #4a4a4a;
$body-link-color: #3c6df1;
$about-page-background-color: #bc0000;

$pill-colors: (
  orange: (
    background-color: #ff7637,
  ),
  green: (
    background-color: #26c492,
  ),
  blue: (
    background-color: #3c6df1,
  ),
  yellow: (
    background-color: #fcbd00,
  ),
  red: (
    background-color: #e13c37,
  ),
  gray: (
    background-color: #b6b7bd,
  ),
  dark-gray: (
    background-color: #4a4a4a,
  ),
);
$disabled-button-background: #d8d8d8;

// links
$link-color: #3c6df1;
$link-color-inactive: #d8d8d8;
$header-link-color-inactive: #3c6df1;
$header-link-color-active: #4a4a4a;

// fonts
$fonts: (
  sans: (
    normal: $sans,
    enhanced: $enhanced-sans,
  ),
  serif: (
    normal: $serif,
    enhanced: $enhanced-serif,
  ),
);

// buttons
$button-styles: (
  font-size: px(11),
  font-weight: bold,
  height: px(36),
  padding: 0 px(20),
  border-radius: px(2),
  box-shadow: 0 px(2) px(10) 0 rgba(0, 0, 0, 0.1),
  primary: (
    color: $white,
    background: linear-gradient(76deg, $link-color, #2294f0 55%, #00c6ee),
  ),
  secondary: (
    color: $link-color,
    background: $white,
  ),
  warning: (
    color: $white,
    background: linear-gradient(257deg, #f26f68, #e13c37, #e13c37),
  ),
  disabled: (
    color: $disabled-button-color,
    background: $disabled-button-background,
  ),
);
