blockquote {
  font-weight: normal;
  font-style: italic;
  text-align: center;
  font-size: px(30);
}

* + blockquote,
blockquote + * {
  margin-top: $base-vertical-space * 4;
}

blockquote,
figcaption {
  font-family: sans-serif;
}
