.placeholder {
  margin: 0;
  background: $background-color;
  width: px(30);
  height: px(30);
  min-width: px(30);
  min-height: px(30);

  &.circle {
    border-radius: 50%;
  }
}
