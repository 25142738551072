.search {
  display: none !important;
  background: $white;

  @include bp(medium) {
    display: grid !important;
    grid-area: search;

    @include isIE() {
      display: flex;
    }
  }
}

.search .searchWithCategory .MuiFormControl-root .MuiOutlinedInput-adornedStart {
  padding-left: 0;
  border-radius: 0;
  font-size: small;
}
.search
  .searchWithCategory
  .MuiFormControl-root
  .MuiOutlinedInput-adornedStart
  .MuiOutlinedInput-root {
  margin: 5px 10px 5px 5px;
  border-radius: 0;
}

.search
  .searchWithCategory
  .MuiFormControl-root
  .MuiOutlinedInput-adornedStart
  .MuiOutlinedInput-root
  .MuiSelect-outlined.MuiSelect-outlined {
  padding: 5px 32px 5px 10px;
  font-size: x-small;
  background-color: #ebebeb;
}
