.icon {
  min-width: px(30);
  min-height: px(30);

  &.medium {
    min-width: px(20);
    min-height: px(20);
  }

  &.small {
    min-width: px(12);
    min-height: px(12);
  }

  &.mobile {
    width: px(20);
    height: px(20);
  }
}
