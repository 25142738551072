.legend-dot {
  width: px(10);
  height: px(10);
  border-radius: 50%;
  margin-top: px(5);
  margin-right: px(5);

  &.red {
    background-color: map-get(map-get($pill-colors, red), background-color);
  }
  &.blue {
    background-color: map-get(map-get($pill-colors, blue), background-color);
  }
  &.yellow {
    background-color: map-get(map-get($pill-colors, yellow), background-color);
  }
  &.orange {
    background-color: map-get(map-get($pill-colors, orange), background-color);
  }
  &.green {
    background-color: map-get(map-get($pill-colors, green), background-color);
  }
  &.gray {
    background-color: map-get(map-get($pill-colors, gray), background-color);
  }
}
