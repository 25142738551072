.project-status-cards {
  * + .card-list-item {
    margin-top: 0;
  }

  display: grid;
  grid-column-gap: px(10);
  grid-row-gap: px(10);
  /* autoprefixer: ignore next */
  grid-template-columns: repeat(auto-fit, minmax(px(300), 1fr));
  grid-template-rows: auto;
  @include IEFlexFix();
  @include isIE() {
    justify-content: space-between;
  }

  .card-list-item {
    display: grid;
    @include IEFlexFix();

    @include isIE() {
      min-width: 24%;
    }

    .card {
      @include isIE() {
        width: 100%;
      }
    }
    .chart-wrapper {
      margin: auto;

      .legend {
        margin: auto;
      }
    }
  }
}

.project-download-button-container {
  align-items: flex-start;
  display: flex;
  float: right;

  @include bp(mobile) {
    margin-left: auto;
  }

  .download-button {
    @include button-styles();
    margin-left: px(10);
    height: px(23);
    align-self: baseline;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
  }

  .download-label {
    margin: 0;
    align-self: center;
  }
}

.project-details-container {
  .header {
    margin-bottom: px(20);
    padding-left: px(20);

    @include bp(mobile) {
      font-size: px(18);
      margin: px(10) 0 px(10) px(5);
    }
  }

  .header-area {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .create-project-buttons {
    display: flex;
    flex-direction: row;

    button {
      margin-left: px(20);
    }
  }

  @include IEFlexFix();
}

.total-counter {
  font-size: px(13);
  font-weight: normal;
  color: $body-text-color;
  margin-left: px(35);
  @include bp(mobile) {
    margin-left: auto;
  }
}
