.signin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: px(20);
  background-image: $inline-background-image;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-color: $signin-background-color;
  background-size: cover;

  .signin-error-container {
    max-width: 95vw;
    width: px(650);
    height: px(60);
    border-radius: px(4);
    background-color: $white;
    display: flex;
    align-items: center;
    padding: 0 px(10);
    visibility: hidden;

    @include bp(small) {
      padding: 0 px(70);
    }

    &.active {
      visibility: visible;
    }

    .signin-error {
      margin-left: px(16);
    }
  }

  .signin-loading-container {
    position: relative;
    width: px(340);
    height: px(60);
  }

  .signin-button {
    @include button-styles();
    width: px(300);
    height: px(60);
    border: none;
    background: $white;
    max-width: 95vw;
    &.hide {
      display: none;
    }
  }

  .legal-text {
    max-width: 95vw;
    height: px(32);
    font-size: px(14);
    font-stretch: condensed;
    line-height: 1.14;
    text-align: center;
    color: $white;
    margin-top: px(160);

    .legal-text-link {
      text-decoration: underline;
    }
  }
}
