.pill {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: px(30);
  height: px(18);
  min-width: px(69);

  .pill-text {
    color: $white;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 px(10);
  }

  &.icon-pill {
    min-width: px(32);
  }

  &.pill-color-orange {
    background: map-get(map-get($pill-colors, orange), background-color);
  }

  &.pill-color-blue {
    background: map-get(map-get($pill-colors, blue), background-color);
  }

  &.pill-color-green {
    background: map-get(map-get($pill-colors, green), background-color);
  }

  &.pill-color-yellow {
    background: map-get(map-get($pill-colors, yellow), background-color);
  }

  &.pill-color-red {
    background: map-get(map-get($pill-colors, red), background-color);
  }

  &.pill-color-gray {
    background: map-get(map-get($pill-colors, gray), background-color);
  }
  &.pill-color-dark-gray {
    background: map-get(map-get($pill-colors, dark-gray), background-color);
  }
}
